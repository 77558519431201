/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-04-20 09:02:50
 * @LastEditTime: 2021-10-20 14:14:01
 */
import axios from 'axios'
let Api = {
	//开发
	// fileUpload: "http://121.41.38.209:8080/zjut-volunteer-server/common/uploadFile/upload",
	//生产
	fileUpload: "https://www.api.volunteer.zjut.edu.cn/api/common/uploadFile/upload",

}
// 文件流上传
export function upLoadFile(params) {
	return axios({
		method: 'post',
		url: Api.fileUpload,
		data: params
	})
}

// 文件直传
// export function upLoadFile () {}
