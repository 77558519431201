/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-23 14:37:15
 */
import axios from '@/utils/request'
let Api = {
    materialsList: "/materialCollection/list",
    materialsDetail: "/materialCollection/",
    materialsAdd: "/materialCollection/",
    materialsDelete: "/materialCollection/",
    materialsEdit: "/materialCollection/",
	materialsUpload: "/materialCollection/uploadFile",
    materialsFileDelete: "/materialCollection/details/",
    enableHandleFile: "/materialCollectionDetails/org/",
}
// 材料收集列表
export function listMaterials(params) {
    return axios({
        method: 'get',
        url: Api.materialsList,
        params: params
    })
}
// 材料收集详情
export function detailMaterials(id, params) {
    return axios({
        method: 'get',
        url: Api.materialsDetail + id,
        params: params
    })
}
// 材料收集新增
export function addMaterials(params) {
    return axios({
        method: 'post',
        url: Api.materialsAdd,
        data: params
    })
}
// 材料收集删除
export function deleteMaterials(id, params) {
    return axios({
        method: 'delete',
        url: Api.materialsDelete + id,
        params: params
    })
}
// 材料收集编辑
export function editMaterials(id, params) {
    return axios({
        method: 'put',
        url: Api.materialsEdit + id,
        data: params
    })
}
// 分会材料上传
export function uploadMaterials(params) {
    return axios({
        method: 'put',
        url: Api.materialsUpload,
        data: params
    })
}
// 材料收集删除
export function deleteMaterialsFile(id, params) {
    return axios({
        method: 'delete',
        url: Api.materialsFileDelete + id,
        params: params
    })
}
// 是否能上传下载
export function handleFileEnable(id, params) {
    return axios({
        method: 'post',
        url: Api.enableHandleFile + id,
        params: params
    })
}
