<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="allMaterialsDetail" v-loading="loading">
		<common-title :title="'材料收集详情'" />
		<div class="allMaterialsDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">材料收集</span>
						<el-input readonly="readonly" v-model="allMaterialsDetail.name" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">上传状态</span>
						<span class="common-table-text">{{allMaterialsDetail.status == 0?"未上传":"已上传"}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">发布人</span>
						<el-input readonly="readonly" v-model="allMaterialsDetail.createBy" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">截止时间</span>
						<span class="common-table-text">{{dateFormat(allMaterialsDetail.deadline)}}</span>
					</div>
				</div>
			</div>
			<div class="">
				<div class="allMaterialsDetail-title">
					分会上传记录
				</div>
				<el-table :data="allMaterialsDetail.materialCollectionDetails" border>
					<el-table-column prop="organizationName" label="分会">
					</el-table-column>
					<el-table-column prop="fileName" label="文件名称">
						<template slot-scope="scope">
							<span class="table-action-href" @click="actionFn('downLoad', scope)"
								v-if="scope.row.fileName">{{scope.row.fileName}}</span>
							<span @click="handleUpLoadFile(scope)" style="color: #F5803E;cursor: pointer;"
								v-else>点击上传文件</span>
						</template>
					</el-table-column>
					<el-table-column prop="uploader" label="上传人">
					</el-table-column>
					<el-table-column prop="createTime" label="上传时间">
						<template slot-scope="scope">
							<span>{{scope.row.updateTime}}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="action" label="操作">
						<template v-if="scope.row.fileUrl" slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('downLoad', scope)">下载</span>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>

<script>
	import {
		detailMaterials,
		uploadMaterials,
		handleFileEnable,
	} from "@/api/materials";
	import {
		upLoadFile
	} from "@/api/OSS";
	export default {
		data() {
			return {
				allMaterialsDetail: {},
				tableData: [],
				materialsId: "",
				limitSize: 1024,
				upLoadUrl: "",
				upLoadData: {},
				loading:false,
				isEnable:true
			};
		},
		watch: {},
		mounted() {
			this.materialsId = this.$route.query.materialsId
			this.getMaterialsDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				}
			},
			enableHandleFile(id) {
				return new Promise((resolve, reject) => {
					handleFileEnable(id, {
						id: id
					}).then((res) => {
						if (res.code == 0) {
							resolve(res.data)
						} else {
							resolve(false)
						}
					})
				})
			},
			getMaterialsDetail() {
				detailMaterials(this.materialsId, {
					id: this.materialsId
				}).then((res) => {
					if (res.code == 0) {
						this.allMaterialsDetail = res.data
					}
				})
			},
			async handleUpLoadFile(scope) {
				this.upLoadData = scope.row
				document.querySelector(".uploadImage input").click();
				// let enable = await this.enableHandleFile(scope.row.organizationId)
				// if (enable) {
				// 	this.upLoadData = scope.row
				// 	document.querySelector(".uploadImage input").click();
				// } else {
				// 	this.$message({
				// 		message: "暂无当前组织的上传权限~",
				// 		type: "error"
				// 	});
				// }
			},
			// handleUpLoadFile(scope) {
			// 	this.upLoadData = scope.row
			// 	document.querySelector(".uploadImage input").click();
			// 	// let enable = await this.enableHandleFile(scope.row.organizationId)
				
			// 	// if (enable) {
			// 	// 	this.upLoadData = scope.row
			// 	// 	document.querySelector(".uploadImage input").click();
			// 	// } else {
			// 	// 	this.$message({
			// 	// 		message: "暂无当前组织的上传权限~",
			// 	// 		type: "error"
			// 	// 	});
			// 	// }
			// },
			async actionFn(type, scope) {
				switch (type) {
					case "downLoad":
						if (scope.row.fileUrl) {
							let enable = await this.enableHandleFile(scope.row.organizationId)
							if (enable) {
								// window.open(scope.row.fileUrl)
								let a = document.createElement('a')
								a.target = "_blank"
								a.href = scope.row.fileUrl
								a.download = scope.row.fileName
								a.dispatchEvent(new MouseEvent('click', {
									bubbles: true,
									cancelable: true,
									view: window
								}))
							} else {
								this.$message({
									message: "暂无当前组织的下载权限~",
									type: "error"
								});
							}
						}
						break;
				}
			},
			// 上传按钮   限制图片大小
			async beforeUpload(file) {
				// console.log("beforeUpload", file);
				let enable = await this.enableHandleFile(this.upLoadData.organizationId)
				if(!enable){
					this.isEnable = false
					return
				}
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 1024MB!");
					this.isEnable = false
					return
				}
				// let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				// if (fileSuffix != "xlsx" && fileSuffix != "xls" && fileSuffix != "doc" && fileSuffix != "docx" && fileSuffix != "rar" && fileSuffix != "zip"&&fileSuffix != "pdf") {
				// 	this.$message({
				// 		message: "上传文件只能是xlsx、xls、doc、docx、rar、zip、pdf格式!",
				// 		type: "error"
				// 	});
				// 	this.isEnable = false
				// 	return
				// }
				this.upLoadData.fileName = file.name
			},
			handleCustomRequest(data) {
				if(this.isEnable===false){
					return
				}
				this.loading = true
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFile(formData).then((result) => {
					// console.log("上传成功", res.data.data.accessUrl)
					this.upLoadData.fileUrl = result.data.data.accessUrl
					uploadMaterials(this.upLoadData).then((res) => {
						if (res.code == 0) {
							this.$message({
								message: "分会文件上传成功~",
								type: "success"
							});
							this.getMaterialsDetail()
						}else{
							this.$message({
								message: res.msg,
								type: "success"
							});
						}
						this.loading = false
					}).catch(()=>{
						this.loading = false
					})
				}).catch(()=>{
					this.loading = false
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.allMaterialsDetail-btn .el-button--default {
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	/deep/.el-textarea__inner {
		resize: none;
		height: 72px;
		border: 0;
	}

	.table-action-href {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #1CABB9;
		text-decoration: underline;
		cursor: pointer;
	}

	.allMaterialsDetail {

		.allMaterialsDetail-content {
			padding: 0 20px 20px 20px;

			.allMaterialsDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
			}

			.allMaterialsDetail-btn {
				display: flex;
				margin-top: 20px;
			}
		}
	}
</style>
